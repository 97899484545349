import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { IQuestionService } from './interfaces/question.service';
import { NgModule } from '@angular/core';
import { QuestionService } from './services/question-service.service';

@NgModule({
    declarations: [],
    imports: [CommonModule, HttpClientModule],
    providers: [
        {
            provide: IQuestionService,
            useExisting: QuestionService
        }
    ]
})
export class QuestionsModule {}
