import {
    API_KEY_TOKEN,
    API_URL_TOKEN
} from 'src/app/shared/configurations/api-token';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { IKeyEncryptionService } from 'src/app/shared/security/interfaces/key-encryption.service';
import { Observable } from 'rxjs';

@Injectable()
export class ApiKeyInterceptor implements HttpInterceptor {
    constructor(
        @Inject(API_KEY_TOKEN) private _apiKey: string,
        @Inject(API_URL_TOKEN) private _apiUrl: string,
        private _encryptionService: IKeyEncryptionService
    ) {}

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (!request.url.startsWith(this._apiUrl)) {
            return next.handle(request);
        }

        const key = this._encryptionService.encryptKeyWithTimestamp(
            this._apiKey
        );

        const newRequest = request.clone({
            headers: request.headers.set('x-api-key', key)
        });

        return next.handle(newRequest);
    }
}
