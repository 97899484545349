import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { NgModule } from '@angular/core';
import { QuestionGuard } from './guards/question.guard';
import { SecurityModule } from '../shared/security/security.module';
import { UnauthorizedPageComponent } from './components/unauthorized-page/unauthorized-page.component';

@NgModule({
    declarations: [UnauthorizedPageComponent],
    imports: [CommonModule, MatButtonModule, MatCardModule, SecurityModule],
    exports: [UnauthorizedPageComponent],
    providers: [QuestionGuard]
})
export class AuthModule {}
