import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent {
    @Input() toolbarTitle: string = '';
    @Input() toolbarLogoImage: string = '';

    constructor() {}
}
