import { BehaviorSubject, Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';

import { API_URL_TOKEN } from 'src/app/shared/configurations/api-token';
import { HttpClient } from '@angular/common/http';
import { IAnsweredQuestions } from 'src/app/answers/models/answered-questions';
import { IGetQuestionsDTO } from 'src/app/data/models/get-questions';
import { IQuestion } from '../models/question';
import { IQuestionService } from '../interfaces/question.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class QuestionService implements IQuestionService {
    private _questions: BehaviorSubject<IQuestion[]> = new BehaviorSubject<
        IQuestion[]
    >([]);
    private _answers: IAnsweredQuestions[] = [];
    private _webcamSnapshotInterval: number = -1;
    private _challengeCode: string = '';
    private _timeLimitInSeconds: number = -1;

    constructor(
        private http: HttpClient,
        @Inject(API_URL_TOKEN) private apiUrl: string
    ) {}

    public get questions(): Observable<IQuestion[]> {
        return this._questions.asObservable();
    }

    public get answers(): IAnsweredQuestions[] {
        return this._answers;
    }

    public get webcamSnapshotInterval(): number {
        return this._webcamSnapshotInterval;
    }

    public get challengeCode(): string {
        return this._challengeCode;
    }

    public get timeLimitInSeconds(): number {
        return this._timeLimitInSeconds;
    }

    public answerQuestion(answer: IAnsweredQuestions) {
        this._answers.push(answer);
    }

    public getQuestions(challengeCode: string): Observable<IGetQuestionsDTO> {
        return this.http
            .get<IGetQuestionsDTO>(this.apiUrl + '/questions/' + challengeCode)
            .pipe(
                tap((response: IGetQuestionsDTO) => {
                    this._questions.next(response.questions);
                    this._webcamSnapshotInterval =
                        response.webcamSnapshotIntervalInSeconds;
                    this._challengeCode = response.challengeCode;
                    this._timeLimitInSeconds = response.timeLimitInSeconds;
                })
            );
    }

    public sendAnswers(
        answers: IAnsweredQuestions[],
        challengeCode: string
    ): Observable<any> {
        return this.http.post(this.apiUrl + '/questions/' + challengeCode, {
            answers
        });
    }
}
