import { RouterModule, Routes } from '@angular/router';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { QuestionGuard } from './auth/guards/question.guard';
import { UnauthorizedPageComponent } from './auth/components/unauthorized-page/unauthorized-page.component';

const routes: Routes = [
    {
        path: 'begin',
        loadChildren: () =>
            import('./begin-challenge/begin-challenge.module').then(
                (m) => m.BeginChallengeModule
            )
    },
    {
        path: 'challenge',
        loadChildren: () =>
            import('./answer-questions/answer-questions.module').then(
                (m) => m.AnswerQuestionsModule
            ),
        canActivate: [QuestionGuard]
    },
    {
        path: 'results',
        loadChildren: () =>
            import('./view-results/view-results.module').then(
                (m) => m.ViewResultsModule
            ),
        canActivate: [QuestionGuard]
    },
    {
        path: 'unauthorized',
        component: UnauthorizedPageComponent
    },
    {
        path: '**',
        pathMatch: 'full',
        loadChildren: () =>
            import('./home-page/home-page.module').then((m) => m.HomePageModule)
    }
];

@NgModule({
    declarations: [],
    imports: [CommonModule, RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}
