import * as CryptoJS from 'crypto-js';

import { Inject, Injectable } from '@angular/core';

import { API_KEY_TOKEN } from '../../configurations/api-token';
import { IKeyEncryptionService } from '../interfaces/key-encryption.service';

@Injectable()
export class KeyEncryptionService implements IKeyEncryptionService {
    constructor(@Inject(API_KEY_TOKEN) private apiKey: string) {}

    public encryptKeyWithTimestamp(key: string): string {
        return CryptoJS.AES.encrypt(key + Date.now(), this.apiKey).toString();
    }
}
