import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-unauthorized-page',
    templateUrl: './unauthorized-page.component.html',
    styleUrls: ['./unauthorized-page.component.css']
})
export class UnauthorizedPageComponent {
    constructor(private _router: Router) {}

    returnToHomePage(): void {
        this._router.navigate(['/home'], {
            replaceUrl: true
        });
    }
}
