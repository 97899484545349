import { CommonModule } from '@angular/common';
import { IKeyEncryptionService } from './interfaces/key-encryption.service';
import { KeyEncryptionService } from './services/key-encryption.service';
import { NgModule } from '@angular/core';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        {
            provide: IKeyEncryptionService,
            useClass: KeyEncryptionService
        }
    ]
})
export class SecurityModule {}
