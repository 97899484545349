import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { QuestionService } from './questions/services/question-service.service';
import { QuestionsModule } from './questions/questions.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        AuthModule,
        BrowserModule,
        BrowserAnimationsModule,
        QuestionsModule,
        SharedModule
    ],
    providers: [QuestionService],
    bootstrap: [AppComponent]
})
export class AppModule {}
