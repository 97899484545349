<div class="container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>Access Denied</mat-card-title>
            <mat-card-subtitle>You are not authorized to access this route directly</mat-card-subtitle>
        </mat-card-header>
        <img src="../../../../assets/svg/unaauthorized.svg" alt="Access Denied">
        <mat-card-content>
            <p>
                You are not authorized to access this route directly. Please begin the challenge process from the home
                page. If you are seeing this message for any other reason than trying to access routes directly, please
                contact your supervisor.
            </p>
        </mat-card-content>
        <mat-card-actions align="end">
            <button mat-button (click)="returnToHomePage()">Return To The home Page</button>
        </mat-card-actions>
    </mat-card>
</div>