import { IAnsweredQuestions } from 'src/app/answers/models/answered-questions';
import { IGetQuestionsDTO } from 'src/app/data/models/get-questions';
import { IQuestion } from '../models/question';
import { Observable } from 'rxjs';

export abstract class IQuestionService {
    public abstract questions: Observable<IQuestion[]>;
    public abstract answers: IAnsweredQuestions[];
    public abstract challengeCode: string;
    public abstract webcamSnapshotInterval: number;
    public abstract timeLimitInSeconds: number;
    public abstract answerQuestion(answer: IAnsweredQuestions): void;

    public abstract getQuestions(
        challengeCode: string
    ): Observable<IGetQuestionsDTO>;

    public abstract sendAnswers(
        answers: IAnsweredQuestions[],
        challengeCode: string
    ): Observable<any>;
}
