import { API_KEY_TOKEN, API_URL_TOKEN } from './configurations/api-token';
import {
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA,
    NgModule
} from '@angular/core';

import { ApiKeyInterceptor } from '../auth/interceptors/api-key.interceptor';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SnackBarService } from './services/snack-bar/snack-bar.service';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { WebcamComponent } from './components/webcam/webcam.component';
import { WebcamModule } from 'ngx-webcam';
import { environment } from 'src/environments/environment';

@NgModule({
    declarations: [ToolbarComponent, WebcamComponent],
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatToolbarModule,
        WebcamModule
    ],
    providers: [
        SnackBarService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiKeyInterceptor,
            multi: true
        },
        {
            provide: API_KEY_TOKEN,
            useValue: environment.apiKey
        },
        {
            provide: API_URL_TOKEN,
            useValue: environment.apiUrl
        }
    ],
    exports: [ToolbarComponent, WebcamComponent],
    schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
