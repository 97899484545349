import { CanActivate, CanActivateChild, Router } from '@angular/router';

import { IQuestionService } from 'src/app/questions/interfaces/question.service';
import { Injectable } from '@angular/core';

@Injectable()
export class QuestionGuard implements CanActivate, CanActivateChild {
    constructor(
        private _questionService: IQuestionService,
        private _router: Router
    ) {}

    public canActivate(): boolean {
        const hasAccess = this._questionService.challengeCode.length > 0;

        if (!hasAccess) {
            this._router.navigate(['/unauthorized']);
            return hasAccess;
        }

        return hasAccess;
    }

    public canActivateChild(): boolean {
        return this.canActivate();
    }
}
